.light-mode.blue {
  --mc1: #ffffff;
  --mc2: #eef0fa;
  --mc2t: 238, 240, 250;
  --mc3: #dce3f2;
  --mc4: #001c39;
  --mc5: #001c39;
  --mc6: #004882;
  --mc7: #0060ab;
  --mc8: #dce3f2;
  --mc9: #cbd5ec;
  --mc10: #a3c9ff;
  --mc11: #001c39;
  --mc12: #001c39;
  --mc13: #001c39;
  --mc14: #0060ab;
  --mc15: #004882;
  --mc16: #a3c9ff;
  --mc17: #a3c9ff;
  --mc18: #a3c9ff;
  --mc19: #0079d5;
  --mc20: #0f93ff;
  --mc21: #ffffff;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #f5b8af;
  --mc25: #a3c9ff;
  --mc26: #5fbaff;
  --mc27: #00315c;
  --mc28: #ebf1ff;
}

.dark-mode.blue {
  --mc1: #1f1f1f;
  --mc2: #2d3137;
  --mc2t: 43, 49, 54;
  --mc3: #3c4149;
  --mc4: #ebf1ff;
  --mc5: #ebf1ff;
  --mc6: #c0c7d5;
  --mc7: #a3c9ff;
  --mc8: #2d3137;
  --mc9: #3c4149;
  --mc10: #234776;
  --mc11: #ebf1ff;
  --mc12: #ebf1ff;
  --mc13: #ebf1ff;
  --mc14: #a3c9ff;
  --mc15: #c0c7d5;
  --mc16: #575f6b;
  --mc17: #575f6b;
  --mc18: #575f6b;
  --mc19: #a3c9ff;
  --mc20: #a3c9ff;
  --mc21: #1f1f1f;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #f5b8af;
  --mc25: #a3c9ff;
  --mc26: #5fbaff;
  --mc27: #00315c;
  --mc28: #2d3137;
  color-scheme: dark;
}
