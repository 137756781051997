.light-mode.pink-red {
  --mc1: #ffffff;
  --mc2: #fcf1f3;
  --mc2t: 252, 241, 243;
  --mc3: #f8dde2;
  --mc4: #31101b;
  --mc5: #31101b;
  --mc6: #7e525e;
  --mc7: #b4007f;
  --mc8: #f8dde2;
  --mc9: #f4c8d0;
  --mc10: #fcbcdb;
  --mc11: #31101b;
  --mc12: #31101b;
  --mc13: #31101b;
  --mc14: #b4007f;
  --mc15: #7e525e;
  --mc16: #f0b7c5;
  --mc17: #f0b7c5;
  --mc18: #f0b7c5;
  --mc19: #b4007f;
  --mc20: #e0009f;
  --mc21: #ffffff;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #c6ffc7;
  --mc25: #fcbcdb;
  --mc26: #ff2f3b;
  --mc27: #620043;
  --mc28: #ffecf2;
}

.dark-mode.pink-red {
  --mc1: #1f1f1f;
  --mc2: #3b2c33;
  --mc2t: 59, 44, 51;
  --mc3: #4c3942;
  --mc4: #ffecf2;
  --mc5: #ffecf2;
  --mc6: #dac0ca;
  --mc7: #ffafd6;
  --mc8: #3b2c33;
  --mc9: #4c3942;
  --mc10: #6c3353;
  --mc11: #ffecf2;
  --mc12: #ffecf2;
  --mc13: #ffecf2;
  --mc14: #ffafd6;
  --mc15: #dac0ca;
  --mc16: #6d5962;
  --mc17: #6d5962;
  --mc18: #6d5962;
  --mc19: #ffafd6;
  --mc20: #ffafd6;
  --mc21: #1f1f1f;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #c6ffc7;
  --mc25: #fcbcdb;
  --mc26: #ff2f3b;
  --mc27: #620043;
  --mc28: #3c2c3;
  color-scheme: dark;
}
