.light-mode.dark-green {
  --mc1: #ffffff;
  --mc2: #f3f4ee;
  --mc2t: 243, 244, 238;
  --mc3: #dde5d9;
  --mc4: #002108;
  --mc5: #002108;
  --mc6: #284e3c;
  --mc7: #00531f;
  --mc8: #dde5d9;
  --mc9: #cedac8;
  --mc10: #a2d892;
  --mc11: #002108;
  --mc12: #002108;
  --mc13: #002108;
  --mc14: #00531f;
  --mc15: #284e3c;
  --mc16: #a6d0b8;
  --mc17: #a6d0b8;
  --mc18: #a6d0b8;
  --mc19: #006e2c;
  --mc20: #00a747;
  --mc21: #ffffff;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #fea9ff;
  --mc25: #affaff;
  --mc26: #42c279;
  --mc27: #003913;
  --mc28: #e7f5e3;
}

.dark-mode.dark-green {
  --mc1: #1f1f1f;
  --mc2: #2b322b;
  --mc2t: 43, 50, 43;
  --mc3: #3d473d;
  --mc4: #f4fff6;
  --mc5: #f4fff6;
  --mc6: #bdcaba;
  --mc7: #719984;
  --mc8: #2b322b;
  --mc9: #3d473d;
  --mc10: #1d5128;
  --mc11: #f4fff6;
  --mc12: #f4fff6;
  --mc13: #f4fff6;
  --mc14: #719984;
  --mc15: #bdcaba;
  --mc16: #51625d;
  --mc17: #51625d;
  --mc18: #51625d;
  --mc19: #719984;
  --mc20: #719984;
  --mc21: #1f1f1f;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #fea9ff;
  --mc25: #affaff;
  --mc26: #42c279;
  --mc27: #003913;
  --mc28: #2b322b;
  color-scheme: dark;
}
