.light-mode.red {
  --mc1: #ffffff;
  --mc2: #fef1ee;
  --mc2t: 254, 241, 238;
  --mc3: #fddbd4;
  --mc4: #3e0500;
  --mc5: #3e0500;
  --mc6: #7f543b;
  --mc7: #b82100;
  --mc8: #fddbd4;
  --mc9: #fcc6bb;
  --mc10: #f3ba9b;
  --mc11: #3e0500;
  --mc12: #3e0500;
  --mc13: #3e0500;
  --mc14: #b82100;
  --mc15: #7f543b;
  --mc16: #f3ba9b;
  --mc17: #f3ba9b;
  --mc18: #f3ba9b;
  --mc19: #b82100;
  --mc20: #e52c00;
  --mc21: #ffffff;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #d0fbe2;
  --mc25: #fcc6bb;
  --mc26: #ff7676;
  --mc27: #640e00;
  --mc28: #ffede9;
}

.dark-mode.red {
  --mc1: #1f1f1f;
  --mc2: #3b2d2a;
  --mc2t: 59, 45, 42;
  --mc3: #4d3b37;
  --mc4: #ffede9;
  --mc5: #ffede9;
  --mc6: #e0bfb9;
  --mc7: #ffb4a4;
  --mc8: #3b2d2a;
  --mc9: #4d3b37;
  --mc10: #733428;
  --mc11: #ffede9;
  --mc12: #ffede9;
  --mc13: #ffede9;
  --mc14: #ffb4a4;
  --mc15: #e0bfb9;
  --mc16: #715953;
  --mc17: #715953;
  --mc18: #715953;
  --mc19: #ffb4a4;
  --mc20: #ffb4a4;
  --mc21: #1f1f1f;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #d0fbe2;
  --mc25: #fcc6bb;
  --mc26: #ff7676;
  --mc27: #640e00;
  --mc28: #3b2d2a;
  color-scheme: dark;
}
