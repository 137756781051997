.light-mode.violet {
  --mc1: #ffffff;
  --mc2: #f7f2f7;
  --mc2t: 247, 242, 247;
  --mc3: #e7dff2;
  --mc4: #251431;
  --mc5: #251431;
  --mc6: #6b5778;
  --mc7: #6d1aff;
  --mc8: #e7dff2;
  --mc9: #d9ccea;
  --mc10: #cfbdff;
  --mc11: #251431;
  --mc12: #251431;
  --mc13: #251431;
  --mc14: #6d1aff;
  --mc15: #6b5778;
  --mc16: #cfbdff;
  --mc17: #cfbdff;
  --mc18: #cfbdff;
  --mc19: #5300cd;
  --mc20: #6d1aff;
  --mc21: #ffffff;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #f6f4b9;
  --mc25: #cfbdff;
  --mc26: #965aff;
  --mc27: #3a0093;
  --mc28: #f5eeff;
}

.dark-mode.violet {
  --mc1: #1f1f1f;
  --mc2: #322f38;
  --mc2t: 50, 47, 56;
  --mc3: #403d48;
  --mc4: #f5eeff;
  --mc5: #f5eeff;
  --mc6: #cbc3d5;
  --mc7: #cfbdff;
  --mc8: #322f38;
  --mc9: #403d48;
  --mc10: #4c3e76;
  --mc11: #f5eeff;
  --mc12: #f5eeff;
  --mc13: #f5eeff;
  --mc14: #cfbdff;
  --mc15: #cbc3d5;
  --mc16: #615c6b;
  --mc17: #615c6b;
  --mc18: #615c6b;
  --mc19: #cfbdff;
  --mc20: #cfbdff;
  --mc21: #1f1f1f;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #f7f3b9;
  --mc25: #cfbdff;
  --mc26: #965aff;
  --mc27: #3a0093;
  --mc28: #322e3c;
  color-scheme: dark;
}
