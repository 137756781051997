.light-mode.yellow-brown {
  --mc1: #ffffff;
  --mc2: #fdf1ea;
  --mc2t: 253, 241, 234;
  --mc3: #f4e2c8;
  --mc4: #281900;
  --mc5: #281900;
  --mc6: #6e5c2e;
  --mc7: #7e5700;
  --mc8: #f4e2c8;
  --mc9: #f0d7b3;
  --mc10: #ffcd6c;
  --mc11: #281900;
  --mc12: #281900;
  --mc13: #281900;
  --mc14: #7e5700;
  --mc15: #6e5c2e;
  --mc16: #dcc48c;
  --mc17: #dcc48c;
  --mc18: #dcc48c;
  --mc19: #e19f00;
  --mc20: #ffba33;
  --mc21: #281900;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #b6c5f9;
  --mc25: #ffd5bd;
  --mc26: #ffcd6c;
  --mc27: #422c00;
  --mc28: #ffeed9;
}

.dark-mode.yellow-brown {
  --mc1: #1f1f1f;
  --mc2: #372f24;
  --mc2t: 55, 47, 36;
  --mc3: #4a3f30;
  --mc4: #ffeed9;
  --mc5: #ffeed9;
  --mc6: #d6c4aa;
  --mc7: #dcc48c;
  --mc8: #372f24;
  --mc9: #4a3f30;
  --mc10: #7e5700;
  --mc11: #ffeed9;
  --mc12: #ffeed9;
  --mc13: #ffeed9;
  --mc14: #dcc48c;
  --mc15: #d6c4aa;
  --mc16: #6a5c48;
  --mc17: #6a5c48;
  --mc18: #6a5c48;
  --mc19: #dcc48c;
  --mc20: #dcc48c;
  --mc21: #1f1f1f;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #b6c5f9;
  --mc25: #ffd5bd;
  --mc26: #ffcd6c;
  --mc27: #422c00;
  --mc28: #372f24;
  --mc28: #3c2c3;
  color-scheme: dark;
}
