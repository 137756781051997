.light-mode.indigo {
  --mc1: #ffffff;
  --mc2: #f3effa;
  --mc2t: 243, 239, 250;
  --mc3: #e4e0f2;
  --mc4: #211634;
  --mc5: #211634;
  --mc6: #66587b;
  --mc7: #4f33ff;
  --mc8: #e4e0f2;
  --mc9: #d3cdea;
  --mc10: #c5c0ff;
  --mc11: #211634;
  --mc12: #211634;
  --mc13: #211634;
  --mc14: darkblue;
  --mc15: #66587b;
  --mc16: #c5c0ff;
  --mc17: #c5c0ff;
  --mc18: #c5c0ff;
  --mc19: #3600e1;
  --mc20: #4f33ff;
  --mc21: #ffffff;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #fff1b9;
  --mc25: #c5c0ff;
  --mc26: #7c99ff;
  --mc27: #2400a2;
  --mc28: #f3eeff;
  --mc29: #c5c0ff;
}

.dark-mode.indigo {
  --mc1: #1f1f1f;
  --mc2: #312f38;
  --mc2t: 49, 47, 56;
  --mc3: #3f3d48;
  --mc4: #f3eeff;
  --mc5: #f3eeff;
  --mc6: #c8c4d6;
  --mc7: #c5c0ff;
  --mc8: #312f38;
  --mc9: #3f3d48;
  --mc10: #454077;
  --mc11: #f3eeff;
  --mc12: #f3eeff;
  --mc13: #f3eeff;
  --mc14: #c5c0ff;
  --mc15: #c8c4d6;
  --mc16: #5f5d6c;
  --mc17: #5f5d6c;
  --mc18: #5f5d6c;
  --mc19: #c5c0ff;
  --mc20: #c5c0ff;
  --mc21: #1f1f1f;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #fff1b9;
  --mc25: #c5c0ff;
  --mc26: #7c99ff;
  --mc27: #2400a2;
  --mc28: #302f3d;
  --mc29: #5f5d6c;
  color-scheme: dark;
}
