.light-mode.teal {
  --mc1: #ffffff;
  --mc2: #e9f2f4;
  --mc2t: 233, 242, 244;
  --mc3: #d4e6e9;
  --mc4: #001f24;
  --mc5: #001f24;
  --mc6: #3e6374;
  --mc7: #006874;
  --mc8: #d4e6e9;
  --mc9: #c2dce0;
  --mc10: #8bd3df;
  --mc11: #001f24;
  --mc12: #001f24;
  --mc13: #001f24;
  --mc14: #006874;
  --mc15: #3e6374;
  --mc16: #a6ccdf;
  --mc17: #a6ccdf;
  --mc18: #a6ccdf;
  --mc19: #008392;
  --mc20: #00a0b0;
  --mc21: #ffffff;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #f9b2ce;
  --mc25: #a6ccdf;
  --mc26: #5fbaff;
  --mc27: #00363d;
  --mc28: #e2f4f7;
}

.dark-mode.teal {
  --mc1: #1f1f1f;
  --mc2: #293234;
  --mc2t: 41, 50, 48;
  --mc3: #3a474a;
  --mc4: #f6feff;
  --mc5: #f6feff;
  --mc6: #b8cacd;
  --mc7: #8bb1c3;
  --mc8: #293234;
  --mc9: #3a474a;
  --mc10: #004f58;
  --mc11: #f6feff;
  --mc12: #f6feff;
  --mc13: #f6feff;
  --mc14: #8bb1c3;
  --mc15: #b8cacd;
  --mc16: #516164;
  --mc17: #516164;
  --mc18: #516164;
  --mc19: #8bb1c3;
  --mc20: #8bb1c3;
  --mc21: #1f1f1f;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #f9b2ce;
  --mc25: #a6ccdf;
  --mc26: #5fbaff;
  --mc27: #00363d;
  --mc28: #293234;
  color-scheme: dark;
}
