.light-mode.green {
  --mc1: #ffffff;
  --mc2: #f2f4f2;
  --mc2t: 242, 244, 242;
  --mc3: #dbe5e0;
  --mc4: #00201a;
  --mc5: #00201a;
  --mc6: #386665;
  --mc7: #006b5b;
  --mc8: #dbe5e0;
  --mc9: #cad9d1;
  --mc10: #8dd8bc;
  --mc11: #00201a;
  --mc12: #00201a;
  --mc13: #00201a;
  --mc14: #006b5b;
  --mc15: #386665;
  --mc16: #a0cfce;
  --mc17: #a0cfce;
  --mc18: #a0cfce;
  --mc19: #008673;
  --mc20: #00a38c;
  --mc21: #ffffff;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #ffafd6;
  --mc25: #a0cfce;
  --mc26: #0ad6d6;
  --mc27: #00382f;
  --mc28: #e2f5ee;
}

.dark-mode.green {
  --mc1: #1f1f1f;
  --mc2: #293230;
  --mc2t: 41, 50, 48;
  --mc3: #3c4946;
  --mc4: #f1fffe;
  --mc5: #f1fffe;
  --mc6: #b8cbc4;
  --mc7: #85b4b2;
  --mc8: #293230;
  --mc9: #3c4946;
  --mc10: #005144;
  --mc11: #f1fffe;
  --mc12: #f1fffe;
  --mc13: #f1fffe;
  --mc14: #85b4b2;
  --mc15: #b8cbc4;
  --mc16: #51625d;
  --mc17: #51625d;
  --mc18: #51625d;
  --mc19: #85b4b2;
  --mc20: #85b4b2;
  --mc21: #1f1f1f;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #ffafd6;
  --mc25: #a0cfce;
  --mc26: #0ad6d6;
  --mc27: #00382f;
  --mc28: #293230;
  color-scheme: dark;
}
