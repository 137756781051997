.light-mode.purple {
  --mc1: #ffffff;
  --mc2: #faf1f5;
  --mc2t: 250, 241, 245;
  --mc3: #f1ddec;
  --mc4: #2e1126;
  --mc5: #2e1126;
  --mc6: #78536b;
  --mc7: #a400b6;
  --mc8: #f1ddec;
  --mc9: #eacce3;
  --mc10: #ffadeb;
  --mc11: #2e1126;
  --mc12: #2e1126;
  --mc13: #2e1126;
  --mc14: #a400b6;
  --mc15: #78536b;
  --mc16: #e7b9d5;
  --mc17: #e7b9d5;
  --mc18: #e7b9d5;
  --mc19: #7d008b;
  --mc20: #a400b6;
  --mc21: #ffffff;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #ebf8ad;
  --mc25: #e7b9d5;
  --mc26: #ff4fc3;
  --mc27: #580063;
  --mc28: #ffebfa;
  --mc29: #e7b9d5;
}

.dark-mode.purple {
  --mc1: #1f1f1f;
  --mc2: #362e35;
  --mc2t: 54, 46, 53;
  --mc3: #483d46;
  --mc4: #ffebfa;
  --mc5: #ffebfa;
  --mc6: #d4c1d0;
  --mc7: #fea9ff;
  --mc8: #362e35;
  --mc9: #483d46;
  --mc10: #633664;
  --mc11: #ffebfa;
  --mc12: #ffebfa;
  --mc13: #ffebfa;
  --mc14: #fea9ff;
  --mc15: #d4c1d0;
  --mc16: #695a67;
  --mc17: #695a67;
  --mc18: #695a67;
  --mc19: #fea9ff;
  --mc20: #fea9ff;
  --mc21: #1f1f1f;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #ebf8ad;
  --mc25: #e7b9d5;
  --mc26: #ff4fc3;
  --mc27: #580063;
  --mc28: #362e35;
  --mc29: #695a67;
  color-scheme: dark;
}
