.container {
  background-color: var(--primaryBg);
  height: calc(var(--app-height) - 2.5 * var(--spacing));
  border-radius: 25px;
}
.container .page-content {
  display: flex;
}
.container .page-content .scroll-section {
  height: calc(var(--app-height) - 4 * var(--baseFontSize) - 6 * var(--spacing) - 2.168 * var(--baseFontSize));
  overflow: auto;
}
.container .page-content.mobile-view {
  height: calc(var(--app-height) - 5 * var(--baseFontSize) - 2 * var(--spacing));
  width: 100%;
}
.container .page-content.mobile-view .scroll-section {
  height: calc(var(--app-height) - 4 * var(--baseFontSize) - 5 * var(--spacing) - 2.168 * var(--baseFontSize));
  overflow: auto;
}
.container .page-content.mobile-view .scroll-section > .panel-enclosure {
  background-color: var(--secondaryBg);
}
.container .page-content.mobile-view .scroll-section > .panel-enclosure > app-expand-collapse > .project-sticky-header {
  background-color: var(--secondaryBg);
}
.container .page-content.mobile-view .scroll-section > .card.bg-primary {
  background-color: var(--secondaryBg);
}
.container .page-content.mobile-view .scroll-section .tag:not(.active):not(:hover) {
  background-color: var(--primaryColorLt);
}
.container .page-content .left-section {
  display: flex;
  flex-basis: 66.66%;
  flex-grow: 1;
  background-color: var(--secondaryBg);
  border-radius: 25px;
}
.container .page-content .left-section .side-panel,
.container .page-content .left-section .detail-panel {
  height: calc(var(--app-height) - 4 * var(--baseFontSize) - 6 * var(--spacing));
}
.container .page-content .left-section .detail-panel {
  width: calc(100% - 200px);
}
.container .page-content .left-section .side-panel {
  width: 200px;
  min-width: 200px;
  padding: calc(0.5 * var(--spacing)) var(--spacing);
  padding-left: 0;
  border-right: 2px solid var(--lightBorder);
}
.container .page-content .right-section {
  flex-basis: 33.33%;
  flex-grow: 1;
}
.container .page-content .right-section > .side-panel {
  background-color: var(--secondaryBg);
  border-radius: 25px;
  height: 100%;
  overflow: auto;
}

.profile-picture {
  display: inline-block;
  height: 120px;
  width: 120px;
  transition: transform linear 0.2s;
  background-image: url("../images/avatar.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.profile-picture:not(.no-ov):hover {
  transform: scale(1.2);
}

.project-sticky-header {
  position: sticky;
  z-index: 1;
  top: 0px;
  background: var(--primaryBg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  font-size: var(--baseFontSize);
  line-height: var(--baseFontSize);
  margin: 0;
  padding: var(--spacing);
}

.panel-enclosure {
  border-radius: 15px;
  overflow: visible;
  background: var(--primaryBg);
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.tag {
  display: inline-flex;
  height: 40px;
  min-width: 70px;
  align-items: center;
  justify-content: center;
  padding: var(--spacing);
  border: 1px solid var(--tertiaryColor);
  color: var(--primaryColor);
  border-radius: 50px;
  background-color: var(--secondaryColorLt);
  margin-right: var(--spacing);
  margin-bottom: var(--spacing);
  font-weight: 500;
  transition: all linear 0.2s;
}
.tag:hover, .tag.active {
  border-color: var(--primaryColor);
  background: var(--primaryColor);
  color: var(--primaryColorLt);
}

.progress-container {
  display: flex;
  align-items: flex-end;
  background-color: var(--primaryBg);
  border-radius: 15px;
  overflow: auto;
  padding-top: 10px;
}
.progress-container > .progress-items {
  flex: 1 1 0;
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: column-reverse;
  width: 70px;
}
.progress-container > .progress-items > .progress-label {
  height: 40px;
  min-width: 70px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  text-align: center;
  border-top: 1px solid var(--lightBorder);
  font-weight: 300;
  width: 100%;
  color: var(--secondaryColor);
}
.progress-container > .progress-items > .progress-width-container {
  width: calc(100% - 100px);
  position: relative;
  height: 150px;
  overflow: hidden;
  width: 45px;
  display: flex;
  align-items: flex-end;
}
.progress-container > .progress-items > .progress-width-container > .progress-width {
  width: 20px;
  display: block;
  transition: all linear 0.3s;
  height: 0px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: linear-gradient(0deg, var(--secondaryColorLt) 0%, var(--tertiaryColor) 40%, var(--primaryColor) 100%);
  background-size: 200% 200%;
  animation: gradient 15s ease infinite;
  font-weight: 500;
  font-size: 12px;
}
.progress-container > .progress-items > .progress-width-container > .progress-width:nth-child(1) {
  margin-right: 5px;
}
.progress-container > .progress-items > .progress-width-container > .progress-width > .text-value {
  writing-mode: vertical-lr;
  line-height: 21px;
  padding-top: 5px;
}

.card {
  border-radius: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

@keyframes gradient {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
.project-preview {
  width: 100%;
}
.project-preview > a {
  display: contents;
}
.project-preview > a > .card {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.project-preview > a > .card > img {
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}