@use "./mixins";

// @import url(./mixins.scss);

.container {
  background-color: var(--primaryBg);
  height: calc(var(--app-height) - 2.5 * var(--spacing));
  @include mixins.border-radius(25px);

  .page-content {
    display: flex;

    .scroll-section {
      height: calc(
        var(--app-height) - (4 * var(--baseFontSize)) - (6 * var(--spacing)) -
          (2.168 * var(--baseFontSize))
      );
      overflow: auto;
    }

    &.mobile-view {
      height: calc(
        var(--app-height) - (5 * var(--baseFontSize)) - (2 * var(--spacing))
      );
      width: 100%;

      .scroll-section {
        height: calc(
          var(--app-height) - (4 * var(--baseFontSize)) - (5 * var(--spacing)) -
            (2.168 * var(--baseFontSize))
        );
        overflow: auto;

        > .panel-enclosure {
          background-color: var(--secondaryBg);
          > app-expand-collapse > .project-sticky-header {
            background-color: var(--secondaryBg);
          }
        }

        > .card.bg-primary {
          background-color: var(--secondaryBg);
        }

        .tag:not(.active):not(:hover) {
          background-color: var(--primaryColorLt);
        }
      }
    }

    .left-section {
      display: flex;
      flex-basis: 66.66%;
      flex-grow: 1;
      background-color: var(--secondaryBg);
      @include mixins.border-radius(25px);

      .side-panel,
      .detail-panel {
        height: calc(
          var(--app-height) - (4 * var(--baseFontSize)) - (6 * var(--spacing))
        );
      }

      .detail-panel {
        width: calc(100% - 200px);
      }

      .side-panel {
        width: 200px;
        min-width: 200px;
        padding: calc(0.5 * var(--spacing)) var(--spacing);
        padding-left: 0;
        border-right: 2px solid var(--lightBorder);
      }
    }

    .right-section {
      flex-basis: 33.33%;
      flex-grow: 1;

      > .side-panel {
        background-color: var(--secondaryBg);
        border-radius: 25px;
        height: 100%;
        overflow: auto;
      }
    }
  }
}

.profile-picture {
  display: inline-block;
  height: 120px;
  width: 120px;
  transition: transform linear 0.2s;
  background-image: url("../images/avatar.jpg");
  background-repeat: no-repeat;
  background-size: cover;

  &:not(.no-ov):hover {
    transform: scale(1.2);
  }
}

.project-sticky-header {
  position: sticky;
  z-index: 1;
  top: 0px;
  background: var(--primaryBg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  font-size: var(--baseFontSize);
  line-height: var(--baseFontSize);
  margin: 0;
  // font-family: "gothic", "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
  //   "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  padding: var(--spacing);
}

.panel-enclosure {
  @include mixins.border-radius(15px);
  overflow: visible;
  background: var(--primaryBg);
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.tag {
  display: inline-flex;
  height: 40px;
  min-width: 70px;
  align-items: center;
  justify-content: center;
  padding: var(--spacing);
  border: 1px solid var(--tertiaryColor);
  color: var(--primaryColor);
  @include mixins.border-radius(50px);
  background-color: var(--secondaryColorLt);
  margin-right: var(--spacing);
  margin-bottom: var(--spacing);
  font-weight: 500;
  transition: all linear 0.2s;

  &:hover,
  &.active {
    // background: linear-gradient(
    //   90deg,
    //   var(--tertiaryColorLt) 0%,
    //   var(--tertiaryColorLt) 10%,
    //   var(--tertiaryColor) 80%
    // );
    border-color: var(--primaryColor);
    background: var(--primaryColor);
    color: var(--primaryColorLt);
  }
}

.progress-container {
  display: flex;
  align-items: flex-end;
  background-color: var(--primaryBg);
  @include mixins.border-radius(15px);
  overflow: auto;
  padding-top: 10px;
  // flex-direction: column;

  > .progress-items {
    flex: 1 1 0;
    display: inline-flex;
    align-items: center;
    margin-bottom: 10px;
    flex-direction: column-reverse;
    width: 70px;

    > .progress-label {
      height: 40px;
      min-width: 70px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      text-align: center;
      border-top: 1px solid var(--lightBorder);
      font-weight: 300;
      width: 100%;
      color: var(--secondaryColor);
    }

    > .progress-width-container {
      width: calc(100% - 100px);
      position: relative;
      height: 150px;
      overflow: hidden;
      width: 45px;
      display: flex;
      align-items: flex-end;

      > .progress-width {
        width: 20px;
        display: block;
        transition: all linear 0.3s;
        height: 0px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        background: linear-gradient(
          0deg,
          var(--secondaryColorLt) 0%,
          var(--tertiaryColor) 40%,
          var(--primaryColor) 100%
        );
        background-size: 200% 200%;
        animation: gradient 15s ease infinite;
        font-weight: 500;
        font-size: 12px;

        &:nth-child(1) {
          margin-right: 5px;
        }

        > .text-value {
          writing-mode: vertical-lr;
          line-height: 21px;
          padding-top: 5px;
        }
      }
    }
  }
}

.card {
  @include mixins.border-radius(15px);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

@keyframes gradient {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

// Custom
.project-preview {
  width: 100%;

  > a {
    display: contents;

    > .card {
      height: 250px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;

      > img {
        height: 100%;
        border-radius: 5px;
        box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
          0px 2px 2px 0px rgba(0, 0, 0, 0.14),
          0px 1px 5px 0px rgba(0, 0, 0, 0.12);
      }
    }
  }
}
