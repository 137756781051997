.light-mode.dark-blue {
  --mc1: #ffffff;
  --mc2: #f0f0fa;
  --mc2t: 240, 240, 250;
  --mc3: #dfe2f2;
  --mc4: #001946;
  --mc5: #001946;
  --mc6: #434465;
  --mc7: #0057ce;
  --mc8: #dfe2f2;
  --mc9: #ccd1ea;
  --mc10: #b1c5ff;
  --mc11: #001946;
  --mc12: #001946;
  --mc13: #001946;
  --mc14: #0057ce;
  --mc15: #434465;
  --mc16: #b1c5ff;
  --mc17: #b1c5ff;
  --mc18: #b1c5ff;
  --mc19: #0057ce;
  --mc20: #076eff;
  --mc21: #ffffff;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #ffddb7;
  --mc25: #b1c5ff;
  --mc26: #4fabff;
  --mc27: #002c70;
  --mc28: #eef0ff;
}

.dark-mode.dark-blue {
  --mc1: #1f1f1f;
  --mc2: #2e3038;
  --mc2t: 45, 49, 55;
  --mc3: #3c3e49;
  --mc4: #eef0ff;
  --mc5: #eef0ff;
  --mc6: #c3c6d6;
  --mc7: #b1c5ff;
  --mc8: #2e3038;
  --mc9: #3c3e49;
  --mc10: #304578;
  --mc11: #eef0ff;
  --mc12: #eef0ff;
  --mc13: #eef0ff;
  --mc14: #b1c5ff;
  --mc15: #b3b6c4;
  --mc16: #5a5e6c;
  --mc17: #5a5e6c;
  --mc18: #5a5e6c;
  --mc19: #b1c5ff;
  --mc20: #b1c5ff;
  --mc21: #1f1f1f;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #ffddb7;
  --mc25: #b1c5ff;
  --mc26: #4fabff;
  --mc27: #002c70;
  --mc28: #2e3038;
  color-scheme: dark;
}
