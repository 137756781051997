.light-mode.aqua {
  --mc1: #ffffff;
  --mc2: #ebf1f8;
  --mc2t: 235, 241, 248;
  --mc3: #d7e4ef;
  --mc4: #001e2d;
  --mc5: #001e2d;
  --mc6: #4a607c;
  --mc7: #007fb0;
  --mc8: #d7e4ef;
  --mc9: #c5d8e8;
  --mc10: #81cfff;
  --mc11: #001e2d;
  --mc12: #001e2d;
  --mc13: #001e2d;
  --mc14: #007fb0;
  --mc15: #4a607c;
  --mc16: #b2c8e8;
  --mc17: #b2c8e8;
  --mc18: #b2c8e8;
  --mc19: #009bd5;
  --mc20: #009bd5;
  --mc21: #ffffff;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #ffb2be;
  --mc25: #b2c8e8;
  --mc26: #5fbaff;
  --mc27: #00344b;
  --mc28: #e6f2fd;
}

.dark-mode.aqua {
  --mc1: #1f1f1f;
  --mc2: #2b3136;
  --mc2t: 43, 49, 54;
  --mc3: #3b434a;
  --mc4: #e4f3ff;
  --mc5: #e4f3ff;
  --mc6: #bcc8d3;
  --mc7: #81cfff;
  --mc8: #2b3136;
  --mc9: #3b434a;
  --mc10: #004c6d;
  --mc11: #e4f3ff;
  --mc12: #e4f3ff;
  --mc13: #e4f3ff;
  --mc14: #81cfff;
  --mc15: #bcc8d3;
  --mc16: #546069;
  --mc17: #546069;
  --mc18: #546069;
  --mc19: #81cfff;
  --mc20: #81cfff;
  --mc21: #1f1f1f;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #ffb2be;
  --mc25: #b2c8e8;
  --mc26: #5fbaff;
  --mc27: #00344b;
  --mc28: #2b3136;
  color-scheme: dark;
}
