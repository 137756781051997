.light-mode.brown {
  --mc1: #ffffff;
  --mc2: #fdf1ea;
  --mc2t: 253, 241, 234;
  --mc3: #fae3d1;
  --mc4: #2d1600;
  --mc5: #2d1600;
  --mc6: #7e5637;
  --mc7: #8d4f00;
  --mc8: #fae3d1;
  --mc9: #f7d4ba;
  --mc10: #ffb876;
  --mc11: #2d1600;
  --mc12: #2d1600;
  --mc13: #2d1600;
  --mc14: #8d4f00;
  --mc15: #7e5637;
  --mc16: #e9bf8e;
  --mc17: #e9bf8e;
  --mc18: #e9bf8e;
  --mc19: #8d4f00;
  --mc20: #d57a00;
  --mc21: #ffffff;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #a9eefa;
  --mc25: #ffd5d2;
  --mc26: #ffb876;
  --mc27: #4b2700;
  --mc28: #ffeee1;
}

.dark-mode.brown {
  --mc1: #1f1f1f;
  --mc2: #392e25;
  --mc2t: 57, 46, 37;
  --mc3: #4a3c30;
  --mc4: #ffeee1;
  --mc5: #ffeee1;
  --mc6: #dcc2ad;
  --mc7: #ffb876;
  --mc8: #392e25;
  --mc9: #4a3c30;
  --mc10: #6b3b05;
  --mc11: #ffeee1;
  --mc12: #ffeee1;
  --mc13: #ffeee1;
  --mc14: #ffb876;
  --mc15: #dcc2ad;
  --mc16: #6f5b4a;
  --mc17: #6f5b4a;
  --mc18: #6f5b4a;
  --mc19: #ffb876;
  --mc20: #ffb876;
  --mc21: #1f1f1f;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #a9eefa;
  --mc25: #ffd5d2;
  --mc26: #ffb876;
  --mc27: #4b2700;
  --mc28: #392e25;
  color-scheme: dark;
}
