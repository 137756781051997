.light-mode.orange {
  --mc1: #ffffff;
  --mc2: #fef1ed;
  --mc2t: 254, 241, 237;
  --mc3: #fddccf;
  --mc4: #360f00;
  --mc5: #360f00;
  --mc6: #7e5637;
  --mc7: #a53d00;
  --mc8: #fddccf;
  --mc9: #fcc9b6;
  --mc10: #ffb597;
  --mc11: #360f00;
  --mc12: #360f00;
  --mc13: #360f00;
  --mc14: #a53d00;
  --mc15: #7e5637;
  --mc16: #f0bc96;
  --mc17: #f0bc96;
  --mc18: #f0bc96;
  --mc19: #cd4e00;
  --mc20: #f85f00;
  --mc21: #ffffff;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #c9faf8;
  --mc25: #fcc9b6;
  --mc26: #ff9f76;
  --mc27: #591d00;
  --mc28: #ffede7;
}

.dark-mode.orange {
  --mc1: #1f1f1f;
  --mc2: #3b2d28;
  --mc2t: 59, 45, 40;
  --mc3: #4c3a34;
  --mc4: #ffede9;
  --mc5: #ffede9;
  --mc6: #dfc0b4;
  --mc7: #ffb597;
  --mc8: #3b2d28;
  --mc9: #4c3a34;
  --mc10: #71361d;
  --mc11: #ffede9;
  --mc12: #ffede9;
  --mc13: #ffede9;
  --mc14: #ffb597;
  --mc15: #dfc0b4;
  --mc16: #715950;
  --mc17: #715950;
  --mc18: #715950;
  --mc19: #ffb597;
  --mc20: #ffb597;
  --mc21: #1f1f1f;
  --mc22: rgba(0, 0, 0, 0.6);
  --mc23: #ffffff;
  --mc24: #c9faf8;
  --mc25: #fcc9b6;
  --mc26: #ff9f76;
  --mc27: #591d00;
  --mc28: #3b2d28;
  color-scheme: dark;
}
